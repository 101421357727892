import hljs from 'highlight.js';
import { h } from 'preact';
import { getComponentFiles } from 'services/buildApi';
import { joinClasses } from 'utils/utils';
import dateDistance from 'utils/dateDistance.mjs';

import Page from 'components/pages/page';
import HTML from 'components/core/html/html';

import s from 'components/pages/dictionary/dictionary.sss';
import md from 'components/pages/docs/markdownDoc.sss';
// import docs from 'components/pages/docs/docs.sss';
let highlight = (code, lang) => lang ? hljs.highlight(code, { language: lang }).value : hljs.highlightAuto(code).value;

export default class Dictionary extends Page {

	constructor(...args) {
		super(...args);

		this.findMarkdownFiles = this.findMarkdownFiles.bind(this);
		this.renderNavItem = this.renderNavItem.bind(this);
		this.updateFilter = this.updateFilter.bind(this);

		// default state to revert to if needed
		this.defaultState = {
			markdownFiles: [], // (array) containing the name and path to every .md file in the static folder
			filteredFiles: [], // (array) containing a subset of markdownFiles filtered from the user's search query
			recentlyModified: [], // (array) containing all the recently modified js files
			content: '',
			loadingFiles: true
		};

		this.state = { ...this.defaultState };

		this.lastFilterValue = '';
		this.recent = 7;
	}

	componentDidMount(...args) {
		if (typeof super.componentDidMount === 'function') {
			super.componentDidMount(...args);
		}
		// Retrieve the file-structure of the codebase and look for all the .md files
		getComponentFiles().then(this.findMarkdownFiles).catch(() => null).then(() => {
			this.setState({ loadingFiles: false });
		});
	}

	getComponentFilter(query) {
		if (!query) {
			return () => true;
		}
		return file => (file && file.component || '').toLowerCase().indexOf(query.toLowerCase()) !== -1;
	}
	updateFilter(e) {
		let search = e.target.value;

		if (search === this.lastFilterValue) {
			return;
		}
		this.lastFilterValue = search;

		let filteredFiles = this.state.markdownFiles.filter(this.getComponentFilter(search));

		if (filteredFiles.length === 1) {
			let file = filteredFiles[0];
			if (this.state.activeComponent !== file.component) {
				this.getMarkdown(file);
			}
		}

		this.setState({
			filteredFiles: filteredFiles.sort((a, b) => b.component - a.component)
		});
	}

	findMarkdownFiles(fileStructure) {
		const now = Date.now();
		let markdownFiles = [];
		let recentlyModified = [];
		const days = 1000 * 3600 * 24;
		const daysSince = time => Math.round((now - time) / days);
		fileStructure.forEach(file => {
			let name = file.path.split('/').pop();
			let match = name.match(/^(.+)\.([^.]+)$/);
			if (!match) {
				return;
			}
			let component = match[1];
			let extension = match[2];
			let array;
			if (extension === 'md') {
				array = markdownFiles;
			} else if (extension === 'js' && daysSince(file.modified) < this.recent) {
				array = recentlyModified;
			}
			if (!array) {
				return;
			}
			array.push({ ...file, component });
		});

		this.setState({
			recentlyModified,
			markdownFiles,
			filteredFiles: markdownFiles
		});
	}

	getMarkdown(file) {
		// Not ideal to prevent switching while the page is loading, blocks navigation if network is slow
		// but easier atm
		if (this.state.loadingMarkdown) {
			return;
		}
		if (!file || !file.path || file.component === this.state.activeComponent) {
			this.setState({
				activeComponent: undefined,
				content: ''
			});
			return;
		}
		const relativePath = 'static/src/components/' + file.path.replace(/\\/g, '/');
		let url = '/documentation?file=' + encodeURIComponent(relativePath);

		// Fetch the contents of the .md file in plaintext from the endpoint
		this.setState({ activeComponent: file.component, loadingMarkdown: true, getMarkdownError: null });
		fetch(url).then(res => res.text()).then(content => {
			this.setState({
				content,
				loadingMarkdown: false
			});
		}).catch(e => {
			this.setState({
				loadingMarkdown: false,
				getMarkdownError: e
			});
		});
	}

	// Attribute: created (not supported anymore) / modified
	renderRecent(attribute, fileList) {
		if (!fileList || !fileList.length) {
			return;
		}
		let markdownFiles = this.state.markdownFiles || [];

		return (
			<section class={s.grouping}>
				<h1 class={s.heading}>{`${attribute} in the last ${this.recent} days`}</h1>
				<ol class={s.list}>
					{fileList.sort((a, b) => b[attribute] - a[attribute]).map(file => {
						let matchingMd = markdownFiles.find(md => md.component === file.component);
						return (
							<li class={s.component} key={file.component}>
								<button
									class={s.name}
									onClick={() => this.getMarkdown(matchingMd)}
									disabled={!matchingMd}
								>
									{file.component}
								</button>
								<span class={s.time}>{attribute + ' ' + dateDistance(new Date(file[attribute]))}</span>
							</li>
						);
					})}
				</ol>
			</section>
		);
	}

	renderNavItem(file) {
		if (!file || !file.component) {
			return;
		}

		const isActive = file.component === this.state.activeComponent;

		// Show a 'new' or 'updated' indicator above any component that's changed (priority to new)
		const isNew = file.created < this.recent;
		const isRecent = !isNew && file.modified < this.recent;
		return (
			<li class={s.component} key={file.component}>
				<button
					class={joinClasses(isActive && s.active, isRecent && s.recent, isNew && s.new)}
					onClick={() => this.getMarkdown(file)}
				>
					{file.component}
				</button>
			</li>
		);
	}

	render(props, state) {
		let filteredFiles = state.filteredFiles || [];
		let isFiltered = filteredFiles.length !== (state.markdownFiles || []).length;

		let display;
		if (state.loadingMarkdown) {
			display = <div class={s.loading} key="loading" />;
		} else if (state.getMarkdownError) {
			display = <div class={s.error} key="error">Error getting the markdown file, try again later</div>;
		} else if (state.activeComponent) {
			display = (
				<HTML
					key="content"
					class={joinClasses(md.container, s.markdown)}
					content={state.content}
					markdown
					markdownOptions={{ highlight }}
				/>
			);
		}
		if (!display && !isFiltered) {
			display = (
				<article class={s.instructions} key="instructions">
					<h1 class={s.title}>The Component Dictionary</h1>
					<div class={s.recent}>
						{this.renderRecent('modified', this.state.recentlyModified)}
					</div>
				</article>
			);
		}

		let list = <div class={s.loading} key="loading" />;
		if (!state.loadingFiles) {
			list = (
				<ul class={s.componentList} key="list">
					{filteredFiles.map(this.renderNavItem)}
				</ul>
			);
		}

		return (
			<div class={s.wrapper}>
				<header class={s.header}>
					<h1 class={s.heading}>Component Dictionary</h1>
					<input
						class={s.search}
						onInput={this.updateFilter}
						type="text"
						placeholder="Search"
						title="Search filter for all components in the dictionary"
					/>
					<nav class={s.nav}>{list}</nav>
				</header>
				<div class={s.documentation}>{display}</div>
			</div>
		);
	}

}
