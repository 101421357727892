import { h } from 'preact';
// import pure from 'utils/pure';
import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';

import s from 'components/shared/editor/formEditor/attributes/nullAttribute.sss';

export default class NullAttribute extends DisplayAttribute {

	constructor(props) {
		super(props);
		this.state.disableRemove = true;
	}

	getInput = () => {
		return (
			<div class={s.disabled}>
				<span class={s.disabledText}>DISABLED</span>
				<button class={s.btn} onClick={this.enable}>Enable Again</button>
			</div>
		);
	};

	enable = () => {
		const { attrKeyPath } = this.props;
		this.props.updateJSON(attrKeyPath, null, false, true);

		// Lets check if it has been set in the disabled items
		// if (jsonPaths[attrKeyPath] && jsonPaths[attrKeyPath].indexOf(activeType) > -1) {
		// 	this.setState({ value: null });
		// } else {
		// 	this.setState({ value: '' });
		// }
		// TODO: make work with different types, only string atm
		// this.onSave();
	}

}
