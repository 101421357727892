import { h } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';

import slug from 'slugify';
import pure from 'utils/pure';
import { handleApiResponse } from 'utils/handleApi';
import { accessNested, joinClasses, parseDate } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';
import * as dataApi from 'services/dataApi';

import Page from 'components/pages/page.js';
import Selector from 'components/shared/form/selector';

import s from 'components/pages/movies/movies.sss';

export default @pure class Docs extends Page {
	constructor() {
		super();
		this.state = {
			regions: { 'gb': true, 'us': false },
			matched: 'unmatched',
			movies: [],
			numVisible: 100,
			sortBy: 'title',
			sortDir: 1,
			providers: { 'webuk': true, 'webus': true },
			loadingApps: true
		};
		this.refresh = this.refresh.bind(this);
		this.setSortBy = this.setSortBy.bind(this);
		this.changeMatchedFilter = this.changeMatchedFilter.bind(this);
		this.changeProviderFilter = this.changeProviderFilter.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.matchTitle = this.matchTitle.bind(this);
		this.saveBulkTitles = this.saveBulkTitles.bind(this);
		this.openBulkCreateTitleDataModal = this.openBulkCreateTitleDataModal.bind(this);
		this.keyTypes = {
			count: 'number',
			releaseDate: 'number'
		};
		this.ytSearchResults = [];
	}

	async componentDidMount(...args) {
		if (typeof super.componentDidMount === 'function') {
			super.componentDidMount(...args);
		}
		Store.on(actions.MOVIES_LIST_SCROLLED, e => {
			if (e.target.scrollHeight - e.target.clientHeight - 10 < e.target.scrollTop) {
				this.setState({ numVisible: this.state.numVisible + 50 });
			}
		});
		await window.getAllData();
		this.setState({ loadingApps: false });
		this.refresh(this.props);
	}

	componentWillReceiveProps(nextProps) {
		this.refresh(nextProps);
	}

	setSortBy(key) {
		if (this.state.sortBy === key) {
			this.setState({ sortDir: -1 * this.state.sortDir });
		} else {
			this.setState({ sortBy: key });
		}
	}

	listScrolled(e) {
		Store.emit(actions.MOVIES_LIST_SCROLLED, e);
	}

	changeMatchedFilter(e) {
		this.setState({ matched: e.target.value });
	}

	changeRegionFilter(region) {
		let regions = this.state.regions;
		regions[region] = !regions[region];
		this.setState({ regions });
		this.refresh();
	}

	changeProviderFilter(provider) {
		let providers = { ...this.state.providers };
		providers[provider] = !providers[provider];
		this.setState({ providers });
	}

	openCreateAppDataModal(data) {
		this.setState({ modal: { type: 'app', data }, ytSearchResults: [] });
		const { movie } = data;
		fetch(`https://www.googleapis.com/youtube/v3/search?part=snippet&key=AIzaSyBtZ_ReV0X_SCLIYosdhO3ICgvjo4HmjbA&q=${movie.title} trailer`)
			.then(handleApiResponse)
			.then(data => {
				let ytVideo = data.items.filter(r => r.id.kind === 'youtube#video').map(v => ({
					id: v.id.videoId,
					title: v.snippet.title,
					channel: v.snippet.channelTitle
				}));
				this.setState({ ytSearchResults: ytVideo });
			});
	}

	openCreateTitleDataModal(data) {
		this.setState({ modal: { type: 'title', data } });
	}

	openBulkCreateTitleDataModal(data) {
		this.setState({ modal: { type: 'bulk-titles', data } });
	}

	closeModal() {
		this.setState({ modal: null });
	}

	refresh(props) {
		let { list, studios, titles, apps, regions } = props || this.props;

		const titlesArr = Object.values(titles);
		const appsArr = Object.values(apps);
		const studiosArr = Object.values(studios);
		const regionsArr = Object.values(regions);

		// wait until the titles have populated in state
		if (!titlesArr.length || !studiosArr.length || !appsArr.length || !regionsArr.length) {
			return;
		}

		let qMovies = {};
		let qCounts = { 'filter[movie_masters]': 'false' };
		let filterRegions = Object.keys(this.state.regions).filter(k => this.state.regions[k]);

		if (!filterRegions.length) {
			return;
		}

		let regionSlugMap = filterRegions.reduce((obj, curr) => {
			obj[curr] = Object.values(list.regions).find(r => r.rel.base === 1 && r.slug === curr)?.id;
			return obj;
		}, {});

		let regionIds = Object.values(regionSlugMap);

		filterRegions.forEach((k, idx) => {
			let regionSafe = k.slice(0, 2);
			qMovies[`filter[vsc][country][${idx}]`] = regionSafe;
			qCounts[`filter[country][${idx}]`] = regionSafe;
		});

		Promise.all([
			dataApi.getMovies(qMovies),
			dataApi.getScreeningCounts(qCounts)
		]).then(([movies, counts]) => {
			let providers = { ...this.state.providers };
			counts = Object.fromEntries(counts.map(o => [o.id, +o.attributes.count]));
			movies = (movies.data || []).reduce((movies, m) => {
				let attr = m.attributes;
				let titleMatching = {
					slug: attr.slug,
					match: attr.slug && Object.values(list.titles).find(t => t.base === 1 && t.slug === attr.slug)
				};

				if (!titleMatching.match) {
					let tslug = slug(attr.title, { lower: true });
					let guess = Object.values(list.titles).find(t => t.slug?.includes(tslug));

					if (guess) {
						titleMatching.guess = guess;
					}
					if (attr.slug) {
						let parentId = m?.meta?.parent_id || 0;
						if (parentId === 0) {
							titleMatching.needsTitleAppData = !movies.find(m => m.titleMatching.needsTitleAppData && m.titleMatching.slug === attr.slug);
						}
					}
				}

				let data = titleMatching.match && regionIds.map(regionId => {
					return {
						regionId,
						regionSlug: list.regions[regionId].slug,
						app: appsArr.find(a => a.rel.title === titleMatching.match.id && a.rel.region === regionId)
					};
				});

				if (providers[attr.provider_id] === undefined) {
					providers[attr.provider_id] = false;
				}

				movies.push({
					id: m.id,
					title: attr.title,
					format: attr.format,
					releaseDate: attr.release_date && formatDateTime(parseDate(attr.release_date), 'yyyy-MM-dd'),
					titleMatching,
					providerData: attr.meta,
					providerId: attr.provider_id,
					count: counts[m.id] || 0,
					data
				});
				return movies;
			}, []);
			this.setState({ movies, providers });
		});

	}

	render() {
		let {
			regions, movies,
			numVisible,
			sortBy, sortDir,
			matched,
			providers,
			modal
		} = this.state;

		if (this.state.loadingApps) {
			return (<div class={s.loading}>Loading all app data...</div>);
		}

		let keys = [
			{ attribute: 'title', label: 'title' },
			{ attribute: 'format', label: 'format' },
			{ attribute: 'releaseDate', label: 'release date' },
			{ attribute: 'matched', label: 'title matching' },
			{ attribute: 'providerId', label: 'provider' },
			{ attribute: 'count', label: 'screenings' },
			{ attribute: 'data', label: 'data' }
		];

		if (sortBy) {
			movies = movies.sort(sortAlpha.bind(null, sortBy, this.keyTypes[sortBy] || 'string', sortDir));
		}

		movies = movies.filter(m => providers[m.providerId]);
		if (matched === 'matched' || matched === 'unmatched') {
			let wantMatched = matched === 'matched';
			movies = movies.filter(m => !!m.titleMatching.match === wantMatched);
		}

		if (modal) {
			switch (modal.type) {
				case 'title':
					modal = this.renderCreateTitleDataModal(modal.data);
					break;
				case 'bulk-titles':
					modal = this.renderBulkCreateTitleDataModal();
					break;
				case 'app':
					modal = this.renderCreateAppDataModal(modal.data);
					break;
			}
		}

		let needsTitleAppDataCount = movies.reduce((prev, m) => prev + m.titleMatching.needsTitleAppData ? 1 : 0, 0);

		return (
			<div class={s.container} onScroll={this.listScrolled}>
				<h1 class={s.title}>Movies</h1>
				<div class={s.content}>
					<form>
						<ul class={s.filters}>
							<li>
								<h6>regions:</h6>
								{Object.keys(regions).map(r => <label>{r}<input type="checkbox" checked={regions[r]} onChange={this.changeRegionFilter.bind(this, r)} /></label>)}
							</li>
							<li>
								<h6>providers:</h6>
								{Object.keys(providers).map(p => <label>{p}<input type="checkbox" checked={providers[p]} onChange={this.changeProviderFilter.bind(this, p)} /></label>)}
							</li>
							<li>
								<h6>matched:</h6>
								<label>yes<input type="radio" name="matched" value="matched" checked={matched === 'matched'} onChange={this.changeMatchedFilter} /></label>
								<label>no<input type="radio" name="matched" value="unmatched" checked={matched === 'unmatched'} onChange={this.changeMatchedFilter} /></label>
								<label>all<input type="radio" name="matched" value="both" checked={matched === 'both'} onChange={this.changeMatchedFilter} /></label>
							</li>
						</ul>
					</form>
					<div class={s.bulkActions}>
						{needsTitleAppDataCount ? <button class={s.button} onClick={this.openBulkCreateTitleDataModal}>Quick connect {needsTitleAppDataCount} titles</button> : null}
					</div>
					<div class={s.listContainer}>
						<ul>
							<li class={joinClasses(s.row, s.listHeader)}>
								{keys.map(key => <div class={joinClasses(s.row, s.listHeader, sortBy === key.attribute && s.active)} onClick={() => this.setSortBy(key.attribute)}>{key.label}</div>)}
							</li>
							{movies.slice(0, numVisible).map(movie => {
								let match;
								if (movie.titleMatching.match) {
									match = accessNested(movie.titleMatching.match, 'attributes.meta.title.slug');
								} else {
									let guess;
									if (movie.titleMatching.guess) {
										guess = (
											<button onClick={() => this.matchGuessTitle(movie)}>
												{movie.titleMatching.guess.slug}
											</button>
										);
									}
									match = <span>match to: {guess}<button onClick={() => this.openCreateTitleDataModal({ movie })}>other</button></span>;
								}

								return (
									<ul class={s.row}>
										<li class={s.col}>
											<h3 class={s.title}>{movie.title}</h3>
										</li>
										<li class={s.col}>{movie.format}</li>
										<li class={s.col}>{movie.releaseDate}</li>
										<li class={s.col}>{match}</li>
										<li class={s.col}>{movie.providerId}</li>
										<li class={s.col}>{movie.count}</li>
										<li class={s.col}>
											{movie.data && movie.data.map(d => (
												<span class={s.appAction}>
													{d.regionSlug}: {d.app ? d.app.id : <button onClick={() => this.openCreateAppDataModal({ movie, regionId: d.regionId, regionSlug: d.regionSlug })}>create</button>}
												</span>
											))}
										</li>
									</ul>
								);
							})}
						</ul>
					</div>
					{!!modal && <div class={s.modal}><div class={s.close} onClick={this.closeModal}>X</div>{modal}</div>}
				</div>
			</div>
		);

	}

	renderCreateAppDataModal(data) {
		const { movie, regionSlug } = data;

		let webediaOnesheet;
		if (movie.providerData.webedia_onesheet_filename) {
			webediaOnesheet = `https://dx35vtwkllhj9.cloudfront.net/webedia/images/${regionSlug}/${movie.providerData.webedia_onesheet_filename.replace('h','H')}`;
		}

		return (
			<div class={s.modalContent}>
				<h3 class={s.legend}>Add App Data</h3>
				<div>
					{webediaOnesheet && <img class={s.onesheet} onLoad={() => data.webediaOnesheet = webediaOnesheet} src={webediaOnesheet} />}
					<form onSubmit={e => this.saveApp(data, e)}>
						<label>
							<span class={s.spacer}>Studio</span>
							<Selector items={Object.values(this.props.studios)} value={this.props.studios[771]} getLabel="attributes.meta.studio.name" selectMessage="-- Select a studio --" name="studioId" />
							{movie.providerData.distributor && <span class={s.hint}>hint: {movie.providerData.distributor}?</span>}
						</label>
						<label>
							<span class={s.spacer}>Title Slug</span>
							<span>{movie.titleMatching.match.attributes.meta.title.slug}</span>
						</label>
						<label>
							<span class={s.spacer}>Region</span>
							<span>{regionSlug}</span>
						</label>
						<label>
							<span class={s.spacer}>Localised Title*</span>
							<input type="text" name="titleLocale" value={movie.title} />
						</label>
						<label>
							<span class={s.spacer}>Release Date*</span>
							<input type="date" name="releaseDate" value={movie.releaseDate} />
						</label>
						<label>
							<span class={s.spacer}>Youtube Playlist ID</span>
							<input type="text" name="youtubePlaylistID" />
						</label>
						<label>
							<span class={s.spacer}>Youtube Video IDs</span>
							<textarea name="youtubeVideoIDs" value="" placeholder="One per line!" cols="50" rows="4" ref={i => this.$youtubeVideoIDs = i} />
							<ul class={s.ytResults}>
								{this.state.ytSearchResults.map(v => {
									let onClick = () => {
										this.$youtubeVideoIDs.value += (this.$youtubeVideoIDs.value ? '\n' : '') + v.id;
									};
									return (
										<li>
											<button type="button" onClick={onClick}>add</button> <a target="_blank" href={`https://www.youtube.com/watch?v=${v.id}`}>{v.title}</a> from {v.channel}
										</li>
									);
								})}
							</ul>
						</label>
						<button class={s.button} type="submit">save</button>
						<p>* Any prefilled values are suggestions only!</p>
					</form>
				</div>
			</div>
		);
	}

	renderCreateTitleDataModal(data) {
		const { movie } = data;
		return (
			<div class={s.modalContent}>
				<h3 class={s.legend}>Add Title Data</h3>
				<div>
					<Selector items={Object.values(this.props.titles)} getLabel="attributes.meta.title.en" selectMessage="-- Select a title --" onChange={() => this.matchTitleSelected(movie)} />
				</div>
				<p>-- Or add new title below --</p>
				<form onSubmit={e => this.saveTitle(data, e)}>
					<label>
						<input type="text" name="titleEn" value={movie.title} placeholder="English Title" />
					</label>
					<button class={s.button} type="submit">save</button>
				</form>
			</div>
		);
	}

	renderBulkCreateTitleDataModal() {
		let movies = this.state.movies.filter(m => m.titleMatching.needsTitleAppData);
		return (
			<div class={s.modalContent}>
				<h3 class={s.legend}>Bulk Add Title Data</h3>
				<form onSubmit={this.saveBulkTitles}>
					<ul>
						{movies.map(m => (
							<li class={s.title}>
								<label>
									<input value={m.title} size="50" name={m.titleMatching.slug} /><span class={s.slug}>{m.titleMatching.slug}</span>
								</label>
							</li>
						))}
					</ul>
					<button class={s.button} type="submit">save</button>
				</form>
			</div>
		);
	}

	matchTitleSelected(movie, title) {
		this.matchTitle({ movie, title }).then(this.closeModal);
	}

	matchGuessTitle(movie) {
		this.matchTitle({movie, title: movie.titleMatching.guess});
	}

	saveApp({ movie, regionId }, e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		const formData = new FormData(e.target);
		const studioId = formData.get("studioId");
		const titleLocale = formData.get("titleLocale");
		let releaseDate = formData.get("releaseDate");
		if (!releaseDate || !titleLocale || !studioId) {
			// eslint-disable-next-line no-alert
			alert('Missing fields!');
		}

		const youtubeVideoIDs = formData.get('youtubeVideoIDs').replace(/\r\n/g, '\n').split('\n');
		const youtubePlaylistID = formData.get('youtubePlaylistID');
		let app = {
			type: 'app_data',
			attributes: {
				pages: {
					videos: { data: {} }
				},
				meta: {
					title: {
						locale: titleLocale,
						localeSlug: slug(titleLocale, { lower: true, remove: /[^\w\s._~-]/g })
					},
					dates: { release: releaseDate }
				},
				urls: {}
			},
			rel: {
				region: regionId,
				title: movie.titleMatching.match.id,
				studio: studioId
			}
		};

		if (youtubePlaylistID) app.attributes.pages.videos.data.youtubePlaylistID = youtubePlaylistID;
		if (youtubeVideoIDs.length) app.attributes.pages.videos.data.list = youtubeVideoIDs;

		dataApi.createAppData('app_data', app).then(app => {
			Store.emit(actions.NEW_APP, app);
			this.closeModal();
		});
	}

	saveTitle({ movie }, e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		let formData = new FormData(e.target);

		let title = formData.get('titleEn');
		if (!title) {
			// eslint-disable-next-line no-alert
			alert('No Title!');
			return;
		}
		if (title.toLowerCase() === title) {
			// eslint-disable-next-line no-alert
			let sure = confirm('The title you entered is all lowercase.\nThis should be the title, not the slug.\nDo you want to continue ?');
			if (!sure) {
				return;
			}
		}
		dataApi.createAppData('titles', {
			type: 'app_data_titles',
			attributes: {
				meta: {
					title: { en: title, slug: slug(title, { lower: true, remove: /[^\w\s._~-]/g }) }
				}
			}
		}).then(title => {
			Store.emit(actions.NEW_TITLE, title);
			return this.matchTitle({ movie, title });
		}).then(this.closeModal);
	}

	saveBulkTitles(e) {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		let formData = new FormData(e.target);
		let titles = [];
		for (let key of formData.keys()) {
			titles.push({
				type: 'app_data_titles',
				attributes: {
					meta: {
						title: { en: formData.get(key), slug: key }
					}
				}
			});
		}
		return titles.reduce((prom, title) => prom.then(arr => {
			return dataApi.createAppData('titles', title).then(res => arr.concat([res]));
		}), Promise.resolve([])).then(newTitles => {
			Store.emit(actions.NEW_TITLES, newTitles);
			this.closeModal();
		});
	}

	matchTitle({ movie, title }) {
		const slug = title.attributes.meta.title.slug;
		return dataApi.getMovieMaster({
			limit: 1,
			'filter[slug][0]': slug
		}).then(data => data || dataApi.createMovieMasterId({
			slug
		})).then(({ id }) => dataApi.updateProviderTitle(movie.id, {
			movie_master_id: id
		})).then(() => {
			Store.emit(actions.UPDATE_PROVIDER_TITLES, title.id);
			return { movie, title };
		});
	}
}

export const dependencies = {
	list: true,
	studios: true,
	titles: true,
	apps: true,
	regions: true
};

function sortAlpha(attr, type, dir, a, b) {
	let attrA = a[attr];
	let attrB = b[attr];
	if (type === 'string') {
		attrA = attrA && (attrA + '').toLowerCase();
		attrB = attrB && (attrB + '').toLowerCase();
	}
	if (attrA < attrB) return -1 * dir;
	if (attrA > attrB) return dir;
	return 0;
}
