import { h, Component } from 'preact';
import Store from 'store/store';
import * as actions from 'store/actions';

import pLimit from 'p-limit';
import keyBy from 'lodash/keyBy';

import Sidebar from 'components/shared/sidebar/sidebar';
import Notification from 'components/shared/notification/notification';
import Modal from 'components/shared/modal/modal';
import ContextMenu from 'components/shared/contextMenu/contextMenu';
import {
	getAppData,
	getTitleData,
	getStudioData,
	getStudioRegionData,
	getRegionData,
	getBaseData,
	getListData
} from 'services/dataApi';
import { joinClasses, asList } from 'utils/utils';
import dateDistance from 'utils/dateDistance.mjs';
import tabbingDetection from 'utils/tabbingDetection';

import * as pages from 'pages/thundr_pages.js';

import s from 'base/thundr/thundrApp.sss';

const isServer = typeof window === 'undefined';
const onLocal = !isServer && !location.href.includes('thundr.powster.com');

const appDataRequestsLimiter = pLimit(5);

/* linkstate function for convenience (based on developit/linkstate)
----------------------------------*/
Component.prototype.linkState = function(key) {
	let comp = this;
	let cache = comp.__lsc || (comp.__lsc = {});

	return cache[key] || (cache[key] = function(e) {
		let t = e?.target || this;
		let v = t?.nodeName ? (t.type.match(/^che|rad/) ? t.checked : t.value) : e;
		comp.setState({ [key]: v });
	});
};

let loadingData = {
	app: {}, title: {}, region: {}, studio: {}, studioRegion: {}, base: {}
};
const dataFunctions = {
	base: getBaseData,
	region: getRegionData,
	studio: getStudioData,
	studioRegion: getStudioRegionData,
	title: getTitleData,
	app: getAppData
};

export default class ThundrApp extends Component {

	constructor(props) {
		super();

		this.restartServer = this.restartServer.bind(this);
		this.onUserUpdate = this.onUserUpdate.bind(this);

		this.state.appState = Object.assign(Store.get().toJS(), props.initialStore);
		Store.set(this.state.appState);

		this.state.restartWarningHidden = false;
		this.state.matchedTitles = {};
		this.state.notification = null;
		this.state.list = {};

		if (!isServer) {
			Store.emit(actions.PAGE_STATE_LOADED);
			window.Store = Store;
			window.getAllData = this.getAllData.bind(this);
		}

		Store.on('update', () => this.setState({ appState: Store.get() }));
		Store.on('UPDATE_APPS_LIST', () => this.getList());
		Store.on('REQUEST_MISSING', (list) => Promise.all(
			list.map(el => appDataRequestsLimiter(() => Store.get()[el.type + 's']?.[el.id] || this.getSingle(el.type, el.id)))
		));
		Store.on('REQUEST_RESTART', data => this.setState({ restartRequested: data || true, restartWarningHidden: false }));

		// if(typeof window !== 'undefined') {
		// 	if(!localStorage["__API_KEY__"]) localStorage["__API_KEY__"] = prompt("Please input your api key (will be stored in localStorage)");
		// 	window.__API_KEY__ = localStorage["__API_KEY__"];
		// }
	}

	async getList() {
		this.startedLoad = true;
		try {
			let list = await getListData();
			Store.get().set({
				list: Object.fromEntries(Object.entries(list).map(([key, val]) => [key, keyBy(val, 'id')]))
			});
		} catch (e) {
			Store.get().set({ error: true });
		}
	}

	async getSingle(type, id) {
		if (Store.get()[type + 's']?.[id]) {
			return;
		}
		if (!loadingData[type]?.[id]) {
			try {
				loadingData[type] = loadingData[type] || {};
				let fn = dataFunctions[type];
				loadingData[type][id] = fn?.(id)?.then(data => {
					Store.get()[type + 's']?.set(id, data);
					return data;
				});
			} catch (err) {
				console.log('Error getting appData', err);
				Store.get().set({ error: true });
			}
		}
		return loadingData[type]?.[id];
	}

	async getAllData() {
		try {
			const data = await Promise.all([
				getBaseData(),
				getRegionData(),
				getStudioData(),
				getStudioRegionData(),
				getTitleData(),
				getAppData()
			]);
			const [bases, regions, studios, studioRegions, titles, apps] = data.map(d => keyBy(d, 'id'));

			Store.get().set({
				apps,
				bases,
				titles,
				studios,
				studioRegions,
				regions,
			});
		} catch (err) {
			console.log('Error getting appData', err);
			Store.get().set({ error: true });
		}
	}

	async componentDidMount() {
		this.checkForRestartRequest();
		window.addEventListener('resize', this.onWindowResize);
		this.onWindowResize();

		// Make sure to do a render every 5 minutes at least (to show the restart warning when needed)
		this.refreshInterval = setInterval(() => this.forceUpdate(), 5 * 60 * 60 * 1000);
		this.tabbingDetection = tabbingDetection();

		window.addEventListener('user-update', this.onUserUpdate);
		if (window.user?.isAuthenticated) {
			await this.getList();
			// await this.getAllData();
			Store.get().set({ loaded: true });
		}
	}

	componentWillUnmount() {
		clearInterval(this.refreshInterval);
		this.tabbingDetection?.remove();
		window.removeEventListener('resize', this.onWindowResize);
		window.removeEventListener('user-update', this.onUserUpdate);
	}

	onWindowResize() {
		let root = document.documentElement;
		root.style.setProperty('--win-w', root.clientWidth + 'px');
		root.style.setProperty('--win-h', root.clientHeight + 'px');
	}

	async checkForRestartRequest() {
		try {
			const res = await fetch('/misc/is_restart_requested');
			if (!res.ok) {
				return;
			}
			const data = await res.json();
			if (data) {
				this.setState({ restartRequested: data });
			}
		} catch (e) {}
	}

	async onUserUpdate(e) {
		if (!e || !e.detail || !e.detail.isAuthenticated || this.startedLoad) {
			return;
		}
		await this.getList();
		// await this.getAllData();
		Store.get().set({ loaded: true });
	}

	restartServer() {
		this.setState({ restarting: true });
		if (window.socket) {
			window.socket.io.on('reconnect', () => {
				location.reload();
			});
		}
		let APIkey = window.__AUTH_USER__.apiKey || localStorage['__API_KEY__'];
		fetch('/maintenance/restart?key=' + APIkey);
	}

	render(props, { appState, restartWarningHidden, restarting, restartRequested }) {

		// TODO what happens when the page doesn't exist, replace 404, or make it redirect to the home?
		const activePage = appState.activePage;
		const appData = appState.appData;
		const client = appState.client;
		const notification = appState.notification;
		const user = appState.user;

		let pageProps = {
			loaded: !!appState.loaded,
			error: !!appState.error,
			id: activePage.id,
			client,
			user,
			appData,
			pageData: appData.pages[activePage.id].data,
			activePage: activePage,
			query: appState.query || {}
		};

		if (activePage.id === 'apps' || activePage.compName === 'apps') {
			pageProps.appData = appState.appData || {};
			pageProps.list = appState.list || {};
			pageProps.apps = appState.apps || {};
			pageProps.titles = appState.titles || {};
			pageProps.studios = appState.studios || {};
			pageProps.studioRegions = appState.studioRegions || {};
			pageProps.regions = appState.regions || {};
			pageProps.bases = appState.bases || {};
			pageProps.watchedApp = appState.watchedApp || {};
			pageProps.watchedApp = appState.watchedApp || {};
			pageProps.screeningCounts = appState.screeningCounts || {};
			pageProps.visitCounts = appState.visitCounts || {};
			pageProps.activeSessionCounts = appState.activeSessionCounts || {};
			pageProps.providers = appState.providers || [];
		}

		let pageModule = pages[activePage.compName || activePage.id];
		let PageComp = pageModule.default;
		let pageDeps = pageModule.dependencies; //JSON Obj of string dependencies
		if (pageDeps) {
			for (let dep in pageDeps) {
				pageProps[dep] = appState[dep];
			}
		}

		let modalState = appState.modal || {};
		let extraModalProps = {};
		if (typeof modalState.extraProps === 'string') {
			extraModalProps[modalState.extraProps] = appState[modalState.extraProps];
		} else if (modalState.extraProps && typeof modalState.extraProps === 'object') {
			extraModalProps = modalState.extraProps;
		}
		let contextMenuState = appState.contextMenu || {};

		let restartWarning;
		if (!isServer) {
			const fullServerRestartButton = () => (
				<button class={joinClasses(s.restart, restarting && s.loading)} onClick={this.restartServer} disabled={restarting}>
					<div class={s.loadingIcon} />
					Restart
				</button>
			);
			if (onLocal && restartRequested) {
				restartWarning = [
					<div class={s.message}>
						<div class={s.top}>Please restart thundr</div>
						<div class={s.details}>{restartRequested?.author || 'A dev'} marked a recent change as needing a restart</div>
					</div>,
					fullServerRestartButton()
				];
			} else if (onLocal && window.__SERVER_START__ && Date.now() - window.__SERVER_START__ > 48 * 60 * 60 * 1000) {
				restartWarning = [
					<div class={s.message}>
						<div class={s.top}>You last restarted thundr {dateDistance(new Date(window.__SERVER_START__))}</div>
						<div class={s.details}>Restarting often is important to make sure you have the latest features and bug fixes</div>
					</div>,
					fullServerRestartButton()
				];
			} else if (window.__OPENED_AT__ && Date.now() - window.__OPENED_AT__ > 8 * 60 * 60 * 1000) {
				restartWarning = [
					<div class={s.message}>
						<div class={s.top}>You opened this page {dateDistance(new Date(window.__OPENED_AT__))}</div>
						<div class={s.details}>Make sure to refresh thundr pages from time to time to prevent any bug or missing app data</div>
					</div>,
					<button class={s.restart} onClick={() => location.reload()}>Refresh</button>
				];
			}
		}

		let mainClasses = [s.main];

		let mainClass = joinClasses(...mainClasses);

		return (
			<div class={joinClasses(s.app, restartWarning && !restartWarningHidden && s.showRestartWarning)}>
				<div class={s.restartWarning} inert={!restartWarning || !!restartWarningHidden}>
					{restartWarning}
					<button class={s.close} key="close" onClick={() => this.setState({ restartWarningHidden: true })} />
				</div>
				<Modal {...modalState} {...extraModalProps} />
				<ContextMenu {...contextMenuState} />
				<Sidebar key="nav" appData={appData} activePage={activePage} inert={modalState.active} />
				<main class={mainClass} key="main" inert={modalState.active}>
					{/*{activePageComp}*/}
					<PageComp {...pageProps} />
				</main>
				{notification && <Notification notification={notification} key="notification" />}
			</div>
		);
	}
}
