import { h, Component } from 'preact';
import { joinClasses } from 'utils/utils';
import pure from 'utils/pure';
import cloneDeep from 'lodash/cloneDeep';

import s from 'components/shared/editor/formEditor/formRemoveButton.sss';

function stop(e) {
	if (e && typeof e.stopPropagation === 'function') {
		e.stopPropagation();
	}
}

export default @pure class FormRemoveButton extends Component {

	constructor() {
		super();
		this.state.showOptions = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.showOptions && !prevState.showOptions) {
			setTimeout(() => this.base.querySelector(`.${s.overlay} button:last-of-type`)?.focus(), 50);
		}
	}

	resetDefault = (e) => {
		stop(e);

		const { dynamicSchemaRef, path } = this.props;

		const paths = [
			{ path: path, value: null }
		];

		if (dynamicSchemaRef?.[path]) {
			let dynamicSchemaCopy = cloneDeep(dynamicSchemaRef);
			delete dynamicSchemaCopy[path];
			paths.push({
				path: 'dev.dynamicSchemaRef',
				value: dynamicSchemaCopy,
			});
		}

		this.props.updateJSON(paths);
		this.setState({ showOptions: false });
	};

	disable = (e) => {
		stop(e);
		this.props.updateJSON(this.props.path, null, true);
		this.setState({ showOptions: false });
	};

	toggle = (e) => {
		stop(e);
		this.setState({ showOptions: !this.state.showOptions });
	};

	render(props, { showOptions }) {
		let options = [];
		if (!props.parentType) {
			options = [
				{ name: 'reset to default/delete', action: this.resetDefault },
				{ name: 'disable', action: this.disable }
			];
		} else {
			options = [
				{ name: 'remove', action: this.resetDefault }
			];
		}
		return (
			<div class={joinClasses(s.wrapper, showOptions && s.open)}>
				<div class={s.overlay} inert={!showOptions}>
					{options.map(opt => <button class={s.btn} onClick={opt.action} key={opt.name}>{opt.name}</button>)}
					<button class={s.btn} onClick={this.toggle}>cancel</button>
				</div>
				<button class={s.removeBtn} onClick={this.toggle} />
			</div>
		);

		// return (
		// 	<div key="removeTop">
		// 		<button class={s.removeBtn} onClick={this.toggle} key="main" />
		// 	</div>
		// );
	}

}
