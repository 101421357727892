import { h, Component } from 'preact';
import defaultsDeep from '@nodeutils/defaults-deep';
import _baseSchema from 'schema/schema';
import _boltSchema from 'schema/boltSchema';
import Store from 'store/store';
import * as actions from 'store/actions';
import { accessNested, joinClasses, removeClass, triggerAnim } from 'utils/utils';
import { smoothScrollTo } from 'utils/smoothScroll';
import checkCondition from 'utils/checkCondition';
import * as keyboardShortcuts from 'utils/keyboardShortcuts';
// import { getAllProjects } from 'services/behanceAPI.js';
// import pure from 'utils/pure';
import ScrollBox from 'components/shared/scrollBox/scrollBox';
import Attribute from 'components/shared/editor/formEditor/attributes/attribute';
import AddNewModal from 'components/shared/modal/addNewModal';
// import FormAddNewButton from 'components/shared/editor/formEditor/formAddNewButton';
import FormBranch from 'components/shared/editor/formEditor/formBranch';
// import LEVELS from 'constants/levels.js';

import s from 'components/shared/editor/formEditor/formEditor.sss';

// Schemas, updated later on
let baseSchema = _baseSchema;
let boltSchema = _boltSchema;

const defaultsPerType = {
	boolean: true,
	string: '',
	array: [],
	date: '',
	datetime: '',
	color: 'transparent',
	image: '',
};

function hasOwnProperty(obj, prop) {
	return Object.prototype.hasOwnProperty.call(obj, prop);
}
function parse(str) {
	if (!str) {
		return null;
	}
	if (typeof str === 'object') {
		return str;
	}
	try {
		return JSON.parse(str);
	} catch (e) {}
	return null;
}
function deepCopy(obj) {
	if (!obj) {
		return null;
	}
	if (typeof obj !== 'string') {
		obj = JSON.stringify(obj);
	}
	return parse(obj);
}

function idfy(str) {
	return str.replace(/[^a-z0-9_.:-]/gi, '').replace(/([:.])/g, '\\$1');
}

function removeUselessValues(values, base, n) {
	if (!values || typeof values !== 'object') {
		return values === base ? null : values;
	}
	n = (n || 0) + 1;
	if (!base || n > 10) return values;
	if (Array.isArray(values)) {
		return values;
	}
	return Object.keys(values).reduce((obj, prop) => {
		let val = values[prop];
		let src = base[prop];
		let type = typeof val;
		if (type === typeof src) {
			if (type !== 'object') {
				if (val !== src) {
					obj[prop] = val;
				}
			} else {
				let result = removeUselessValues(val, src, n);
				if (!result || Object.keys(result).length) {
					obj[prop] = result;
				}
			}
		} else {
			obj[prop] = val;
		}
		return obj;
	}, {});
}

// TODO: fix null object
export default class FormEditor extends Component {
	constructor() {
		super();
		this.setActivePath = this.setActivePath.bind(this);
		this.update = this.update.bind(this);
		this.updateJSON = this.updateJSON.bind(this);
		this.showAddNewModal = this.showAddNewModal.bind(this);

		this.state.filter = '';
		this.state.activePath = '';
		this.state.displayDisabled = true;

		this.scrollTo = null;
		Promise.all(
			['schema', 'boltSchema'].map(file => (
				fetch('/schema?file=' + file).then(r => r.ok ? r.json() : null).catch(() => null)
			))
		).then(schemas => {
			baseSchema = schemas[0] || baseSchema;
			boltSchema = schemas[1] || boltSchema;
			if (!this.unmounted) {
				this.update();
			}
		});
		this.schema = baseSchema;
	}

	update(props, state) {
		props = props || this.props;
		state = state || this.state;

		let json = parse(props.json);
		let jsonFull = parse(props.jsonFull);

		let activeJSON = state.displayDisabled ? jsonFull : json;
		let validatedActivePath = state.activePath;
		const paths = validatedActivePath.split('.');
		if (accessNested(jsonFull, 'dev.defaultBase') === 'bolt') {
			this.schema = defaultsDeep({}, boltSchema);
		} else {
			this.schema = defaultsDeep({}, baseSchema);
		}
		let jsonSchema = this.schema;
		paths.pop();
		paths.find((path, index) => {
			path = path.replace(/\[\[\[dot\]\]\]/g, '.');

			// Stop if a prop from the path is null or not an object
			if (!hasOwnProperty(activeJSON, path) || activeJSON[path] === null || typeof activeJSON[path] !== 'object') {
				// Change the active path, so it will only go as far as the last found object
				validatedActivePath = validatedActivePath.split('.').slice(0, index).join('.') + '.';
				return true;
			}

			activeJSON = activeJSON[path];

			// Check if all the children will have the same definition or not!
			// Check exceptions first (if an object has both properties and allOf, use properties if it exists, allOf otherwise
			let jsonSchemaProp = accessNested(jsonSchema, ['properties', path]);
			let allOfList = accessNested(jsonSchema, 'properties.allOf');
			if (jsonSchemaProp) {
				jsonSchema = jsonSchemaProp;
				if (jsonSchema.allOf) {
					jsonSchema = jsonSchema.allOf.reduce((o, e) => {
						let n = e;
						if (hasOwnProperty(e, '$ref')) {
							// Local definitions (let's assume it is one) always start with #/, so lets get rid of that
							const defTypeArr = e.$ref.substring(2).split('/');
							n = defTypeArr.reduce((def, p) => def?.[p], this.schema);
						}
						return defaultsDeep(o, n);
					}, {});
				} else if (hasOwnProperty(jsonSchemaProp, '$ref')) {
					const defTypeArr = jsonSchemaProp.$ref.substring(2).split('/');
					jsonSchema = defTypeArr.reduce((def, p) => def?.[p], this.schema);
					// extend properties of definition
					Object.assign(jsonSchema.properties, jsonSchemaProp.properties);
				} else {
					for (let property in jsonSchemaProp.properties) {
						if (hasOwnProperty(jsonSchemaProp.properties[property], '$ref')) {
							const defTypeArr = jsonSchemaProp.properties[property].$ref.substring(2).split('/');
							const defSchema = defTypeArr.reduce((def, p) => def?.[p], this.schema);
							if (defSchema?.properties) {
								// extend properties of definition
								const merged = Object.assign({}, defSchema, jsonSchemaProp.properties[property]);
								Object.assign(merged.properties, jsonSchemaProp.properties[property].properties);
								jsonSchemaProp.properties[property] = merged;
							}
						}
					}
				}
			} else if (allOfList?.length) {
				jsonSchema = allOfList.find(type => {
					if (!type.attr) {
						return false;
					}
					const attributes = type.attr.split(',');
					if (type.attrValue) {
						return attributes.find(attribute => activeJSON[attribute] === type.attrValue);
					} else {
						return attributes.find(attribute => activeJSON[attribute]);
					}
				}) || allOfList[0];
				if (hasOwnProperty(jsonSchema, '$ref')) {
					const defTypeArr = jsonSchema.$ref.split('/');
					// Local definitions always start with #/
					if (defTypeArr[0] === '#') {
						defTypeArr.shift();
					}
					jsonSchema = defTypeArr.reduce((def, p) => def?.[p], this.schema);
				}
			} else {
				jsonSchema = {};
			}

			if (jsonSchema.extends) {
				const defTypeArr = jsonSchema.extends.split('/');
				if (defTypeArr[0] === '#') {
					defTypeArr.shift();
				}
				const schemaExtension = defTypeArr.reduce((def, p) => def?.[p], this.schema);
				Object.assign(jsonSchema.properties, schemaExtension.properties);
			}
		});


		this.json = json;
		this.jsonFull = jsonFull;
		this.validatedActivePath = validatedActivePath;
		this.activeJSON = activeJSON;
		this.jsonSchema = jsonSchema;

		// Ewwwww (sorry - we should move the base schema detection at mainEdit level)
		window.currentSchema = this.schema;

		this.props.updateSidebarContent?.(this.renderSidebarContent());
	}

	updatePath(newPath) {
		if (typeof newPath !== 'string' || this.unmounted) {
			return;
		}

		// Path validation
		let json = parse(this.props.jsonFull);
		let pathParts = newPath.split('.');
		if (!newPath.endsWith('.')) {
			this.scrollTo = pathParts.pop();
		}
		let validParts = pathParts.findIndex(part => {
			part = part.replace(/\[\[\[dot\]\]\]/g, '.');
			if (!hasOwnProperty(json, part) || json[part] === null || typeof json[part] !== 'object') {
				this.scrollTo = part;
				return true;
			}
			json = json[part];
			return false;
		});
		if (validParts !== -1) {
			pathParts = pathParts.slice(0, validParts);
		}
		newPath = pathParts.map(part => part + '.').join('');

		let stateUpdate = { activePath: newPath };
		this.update(this.props, Object.assign({}, this.state, stateUpdate));
		this.setState(stateUpdate);
	}

	componentWillMount() {
		this.update();

		const path = this.props.path;

		if (path && this.originalPath !== path) {
			this.originalPath = path;
			this.updatePath(this.getPath(path));
		}
	}

	componentDidMount() {
		window.setActivePath = this.setActivePath;
		if (this.$crumbs) {
			this.$crumbs.scroll = 99999999;
		}
		this.actions = this.props.registerActions?.([
			{ value: 'addNewItem', name: 'Add new AppData item', action: this.showAddNewModal, keybinding: ['ctrl', 'M'] }
		]);
		this.shortcutsHandler = keyboardShortcuts.register({
			'ctrl+M': { action: this.showAddNewModal, name: 'Add new AppData item' },
		});
	}

	componentWillReceiveProps(nextProps) {
		const { path } = nextProps;

		this.previousValidatedActivePath = this.validatedActivePath;
		if (path && this.props.path !== path) {
			this.updatePath(this.getPath(path));
		}

		if (nextProps.data?.id !== this.props.data?.id || nextProps.data?.type !== this.props.data?.type) {
			this.updatePath('');
		}
	}

	componentWillUpdate(nextProps, nextState) {
		let hasUpdates = [
			'json',
			'jsonFull'
		].find(k => nextProps[k] !== this.props[k]) || [
			'activePath'
		].find(k => nextState[k] !== this.state[k]);

		if (hasUpdates) {
			this.update(nextProps, nextState);
		}
	}

	componentDidUpdate(oldProps, oldState) {
		if (this.state.activePath !== oldState.activePath) {
			let container;
			try {
				container = this.base?.querySelector('.' + s.jsonContainer);
			} catch (err) {}
			if (container) {
				container.scrollTop = 0;
			}
		}
		if (this.scrollTo && this.scrollingTo !== this.scrollTo) {
			let container;
			let elem;
			try {
				container = this.base?.querySelector('.' + s.jsonContainer);
				elem = this.base?.querySelector(`#attr-${idfy(this.scrollTo)}, [data-attr="${this.scrollTo.replace(/"/g, '')}"]`);
			} catch (err) {}
			if (container && elem) {
				this.scrollingTo = this.scrollTo;
				smoothScrollTo(container, elem, 300, {
					relativeTo: 'middle',
					callback: () => {
						this.scrollTo = null;
						this.scrollingTo = null;
						let inputs = [...elem.querySelectorAll('input, textarea')];
						const elementToFocus = inputs.pop() || elem.querySelector('button');
						elementToFocus?.focus();
						elem.addEventListener('animationend', () => removeClass(elem, s.highlight), { once: true });
						triggerAnim(elem, s.highlight);
					},
				});
			}
		}
		if (this.validatedActivePath !== this.previousValidatedActivePath && this.$crumbs) {
			this.$crumbs.scroll = 99999999;
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
		this.shortcutsHandler?.stop();
		this.actions?.unregister();
	}

	getPath = (path) => {
		if (Array.isArray(path)) path = path.join('.');
		if (!path || path.length <= 1) return '';
		if (!path.endsWith('.')) {
			path += '.';
		}
		return path;
	};

	setActivePath = (newPath) => {
		this.updatePath(newPath);
		// this.setState({ activePath: newPath });
	};

	setErrors = () => {
		// Set an error here
	};

	renameElement = (parentPath, fromName, toName) => {
		if (this.props.branchData) {
			parentPath = `dev.branches.${this.props.branchData.id}.content.${parentPath}`;
		}

		const baseJSON = deepCopy(this.props.activeAppData);

		const tryAccess = (obj, path) =>
			obj && (path.length ? tryAccess(obj[path.shift()], path) : obj);

		const parentObj = tryAccess(baseJSON, parentPath.split('.').filter(e => e));
		if (typeof parentObj !== 'object') return;
		parentObj[toName] = parentObj[fromName];

		delete parentObj[fromName];
		this.props.onChange(baseJSON);
	};

	cleanJSON = (path) => {
		let excluded = [this.props.activeType];
		let above = this.props.getMerged(this.props.data, this.props.branchData?.id, null, excluded)?.mergedFull;
		if (!above) {
			return;
		}
		let current = parse(this.props.activeAppData);

		let accessPath = path;
		if (accessPath.endsWith('.')) accessPath = accessPath.slice(0, -1);
		if (accessPath) {
			above = accessNested(above, accessPath);
			current = accessNested(current, accessPath);
		}

		let newVal = removeUselessValues(current, above);
		if (newVal && typeof newVal === 'object' && !Object.keys(newVal).length) {
			newVal = null;
		}

		this.updateJSON(path, newVal);
	};

	// TODO: move to appDataEditor (no reason this should be specific to the Form Editor)
	updateJSON(path, value, disable = false, enable = false, skipBranch = false) {
		if (!Array.isArray(path)) {
			path = [
				{
					path: path,
					value: value,
					disable: disable,
					enable: enable
				}
			];
		}

		let baseJSON = deepCopy(this.props.activeAppData);
		let baseJSONMerged = deepCopy(this.props.jsonFull);

		path.forEach(d => {
			const { value, disable, enable } = d;
			let { path } = d;
			if (this.props.branchData && !skipBranch) {
				path = `dev.branches.${this.props.branchData.id}.content.${path}`;
			}

			// Find the right path in the object and update (path ex. meta.title.slug)
			if (path.charAt(path.length - 1) === '.') {
				path = path.slice(0, -1);
			}

			let latestObject = baseJSON;
			let latestObjectParent = baseJSON;
			let latestObjectParentPath = '';
			let latestObjectMerged = baseJSONMerged;

			const paths = path.split('.');
			const parentKey = paths.pop().replace(/\[\[\[dot\]\]\]/g, '.');

			paths.forEach((path, id) => {
				path = path.replace(/\[\[\[dot\]\]\]/g, '.');
				// Get the current object for this path
				if (!hasOwnProperty(latestObject, path)) {
					latestObject[path] = {};
				}
				latestObject = latestObject[path];

				// Lets keep track of the parent itself as well
				if (id < paths.length - 1) {
					if (!hasOwnProperty(latestObjectParent, path)) {
						latestObjectParent[path] = {};
					}
					latestObjectParent = latestObjectParent[path];
				}
				latestObjectParentPath = path;

				// lets do the merged as well, why not...
				if (!hasOwnProperty(latestObjectMerged, path)) {
					latestObjectMerged[path] = {};
				}
				latestObjectMerged = latestObjectMerged[path];
			});

			if (value === null && !disable && !enable) {
				if (Array.isArray(latestObject)) {
					latestObject.splice(parentKey, 1);
				} else {
					delete latestObject[parentKey];
				}
			} else if (value === null && disable) {
				// Lets check if there is anything, if there is, lets save it in dev so we can get it back when it's enabled
				// Save the merged version to be able to enable again at lower levels
				if (latestObjectMerged[parentKey]) {
					const currentRoot = (this.props.branchData?.id && !skipBranch && baseJSON.dev.branches[this.props.branchData.id]?.content) || baseJSON;
					if (!currentRoot.dev) currentRoot.dev = {};
					if (!currentRoot.dev.disabledItems) currentRoot.dev.disabledItems = {};
					currentRoot.dev.disabledItems[d.path.replace(/\.$/, '').replace(/\./g, '|')] = latestObjectMerged[parentKey];
				}
				latestObject[parentKey] = null;
			} else if (value === null && enable) {
				// lets check if we have a disabled item for it
				const disabledPath = d.path.replace(/\.$/, '').replace(/\./g, '|');
				const storagePath = ['dev', 'disabledItems', disabledPath];
				// TODO: smarter schema detection (work with allOf, definitions, etc.)
				const schemaDef = accessNested(
					this.schema,
					'properties.' + disabledPath.replace(/\|/g, '.properties.')
				) || {};
				let schemaType = schemaDef.type;
				if (!schemaType && schemaDef.$ref) {
					let ref = schemaDef.$ref.split('/').pop();
					let refData = this.schema.definitions[ref];
					schemaType = refData.type;
				}

				// TODO: we need to provide more backups here
				let standardBackup = hasOwnProperty(defaultsPerType, schemaType) ? defaultsPerType[schemaType] : {};
				const valueOfDisabledItem = baseJSON.dev.disabledItems?.[disabledPath];
				let objFromMerged = accessNested(baseJSONMerged, storagePath, standardBackup);
				latestObject[parentKey] = valueOfDisabledItem || objFromMerged;

				if (accessNested(baseJSON, storagePath)) {
					delete baseJSON.dev.disabledItems[disabledPath];
				}
			} else {
				if (latestObject) {
					// lets check if the value already exists but in a different form (capitals, ...)
					let lowerParent = parentKey.toLowerCase();
					let alreadyExists = !hasOwnProperty(latestObject, parentKey) && Object.keys(latestObject).find(k => k.toLowerCase() === lowerParent);
					if (!path) {
						baseJSON = value;
					} else if (alreadyExists) {
						Store.emit(
							actions.SHOW_MODAL,
							'The element you are trying to add already exists, either in uppercase or lowercase',
							'Error'
						);
					} else {
						if (Array.isArray(latestObject)) {
							latestObject[parentKey] = value;
						} else {
							if (Array.isArray(latestObjectMerged) && Object.keys(latestObject).length < 1) {
								const newV = latestObjectMerged.slice();
								newV[parentKey] = value;
								latestObjectParent[latestObjectParentPath] = newV;
							} else {
								latestObject[parentKey] = value;
							}
						}
					}
				}
			}
		});

		this.props.onChange(baseJSON);
	}

	showAddNewModal() {
		let dev = this.props.activeAppData?.dev;
		Store.emit(actions.SHOW_MODAL,
			<AddNewModal
				key={this.validatedActivePath + Date.now()}
				activePath={this.validatedActivePath}
				updateJSON={this.updateJSON}
				jsonSchema={this.jsonSchema}
				baseJSONSchema={this.schema}
				parentValue={this.activeJSON}
				mergedValue={this.json}
				dynamicSchemaRef={dev?.dynamicSchemaRef}
			/>,
			'Add New Item'
		);
	}

	renderSidebarContent() {
		let activeElement = this.validatedActivePath?.split('.')?.[0];
		return (
			<div class={s.sidebarNav} key="editor">
				<p class={s.navTitle}>Top navigation</p>
				{Object.entries(this.json || {}).map(([key, value]) => {
					if (!value) {
						return;
					}
					let schemaData = this.schema.properties[key];
					let hidden = schemaData?.hidden;
					if (hidden && typeof hidden === 'object') {
						hidden = checkCondition(hidden, this.jsonFull, { localPath: '', currentAttribute: key });
					}
					if (hidden) {
						return;
					}
					let title = schemaData?.title || key;
					return (
						<button
							class={joinClasses(s.navBtn, key === activeElement && s.active)}
							onClick={() => this.setActivePath(key + '.')}
							key={key}
						>
							{title}
						</button>
					);
				})}
			</div>
		);
	}

	render(props) {
		const {
			jsonPaths,
			activeType,
			// activeLevelId,
			activeAppData,
			data,
			movieIdInfo,
		} = props;

		let {
			json,
			jsonFull,
			validatedActivePath,
			activeJSON,
			jsonSchema
		} = this;

		const paths = validatedActivePath.split('.');

		const isApp = activeType === 'apps';

		const attrName = paths.length ? paths[paths.length - 1] : '';
		// Should probably use the merged version
		let dev = (activeAppData?.dev) || {};
		return (
			<div class={s.container}>
				<div class={s.infoContainer}>
					{isApp && (
						<FormBranch
							branches={jsonFull?.dev?.branches}
							appId={data.id}
							thundrAppData={props.thundrAppData}
							activeBranch={props.branchData}
							updateJSON={this.updateJSON}
							edited={props.edited}
							saveData={this.props.saveData}
						/>
					)}
					<div class={s.crumbsContainer}>
						<ScrollBox
							class={s.crumbs}
							scrollableLeftClass={s.hasMoreLeft}
							scrollableRightClass={s.hasMoreRight}
							device="mobile"
							hideScrollerBar
							ref={e => this.$crumbs = e}
						>
							<button class={s.crumb} onClick={() => this.setActivePath('')}>
								Start
							</button>
							{paths.flatMap((path, id) => {
								if (!path) {
									return;
								}
								const subPaths = paths.slice(0, id + 1);
								let pathSchema = subPaths.reduce((schema, p) => schema?.properties?.[p], this.schema);
								path = path.replace(/\[\[\[dot\]\]\]/g, '.');
								let onClick = () => this.setActivePath(subPaths.join('.') + '.');
								let onContextMenu = e => {
									e.preventDefault();
									// let extra = e.ctrlKey || e.metaKey;
									Store.emit(actions.SHOW_CONTEXT_MENU, { x: e.clientX, y: e.clientY }, [
										// { name: 'Copy human path', action: thing, autoClose: true },
										// extra && { name: 'Copy dev path', action: thing, autoClose: true },
										{ name: 'Go there', action: onClick, autoClose: true },
									].filter(e => e));
								};
								let display = pathSchema?.title || path;
								return [
									<div class={s.separator} />,
									<button
										class={s.crumb}
										onClick={onClick}
										onContextMenu={onContextMenu}
										title={display !== path ? path : undefined}
									>
										{display}
									</button>
								];
							})}
						</ScrollBox>
						<div class={s.scrollIndicators} />
					</div>
					<button class={s.addNew} onClick={this.showAddNewModal}>Add New Item</button>
				</div>
				<div class={s.jsonContainer}>
					<Attribute
						value={activeJSON}
						activeValue={activeAppData}
						activeValueFull={jsonFull}
						setErrors={this.setErrors}
						attributeName={attrName}
						key={attrName}
						setActivePath={this.setActivePath}
						base
						attrKeyPath={validatedActivePath}
						activePath={validatedActivePath}
						jsonPaths={jsonPaths}
						updateJSON={this.updateJSON}
						renameElement={this.renameElement}
						cleanJSON={this.cleanJSON}
						activeType={activeType}
						branch={props.branchData}
						baseJSONSchema={this.schema}
						jsonSchema={jsonSchema}
						dynamicSchemaRef={dev.dynamicSchemaRef}
						movieIdInfo={movieIdInfo}
						appId={data.id}
					/>
				</div>
			</div>
		);
	}
}
