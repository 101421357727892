import { h } from 'preact';
// import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import * as dataApi from 'services/dataApi';

import pure from 'utils/pure';
import { condClass } from 'utils/utils';
import formatDateTime from 'utils/formatDateTime';

import Page from 'components/pages/page.js';
import Table from 'components/shared/table/table.js';
// import { Link } from 'components/core/link/link.js';

import s from 'components/pages/reports/reports.sss';

export default @pure class Reports extends Page {
	constructor() {
		super();
		this.reports = [
			{
				name: 'visits',
				baseQuery: {
					'filter[events][id][0]': '972967de-895b-11e5-beef-6f25b04df1fe',
					'group_by[0]': 'title',
					only_cached: 'true'
				},
				keys: ['unique', 'title']
			}
		];
		this.keyTypes = {
			total: 'number',
			unique: 'number'
		};
		this.columnClasses = {
			title: s.title,
			total: s.total,
			unique: s.unique
		};
		this.state.selectedReport = null;
		this.state.start = null;
		this.state.end = null;
		this.state.data = null;
		this.getReportData = this.getReportData.bind(this);
		this.cache = {};
	}

	getReportData(report) {
		let q = cloneDeep(report.baseQuery);

		if (this.state.start) {
			q['filter[start]'] = formatDateTime(new Date(this.state.start), 'yyyy-MM-dd');
		}
		if (this.state.end) {
			q['filter[end]'] = formatDateTime(new Date(this.state.end), 'yyyy-MM-dd');
		}

		let version = Date.now();
		this.setState({ dataType: report.name, loading: true, error: null, version });

		let tryGet = () => {
			let reqStart = Date.now();
			return dataApi.getEventStats(q).then(data => {
				if (this.state.version !== version) {
					return;
				}
				let keys = report.keys;
				if (!keys && data.length) {
					keys = Object.keys(data[0]);
				}
				this.setState({
					loading: false,
					keys: keys || [],
					data: data.map(d => {
						let val = d.attributes;
						val.total = parseInt(val.total);
						// if (report.name === 'visits') val.total = '-';
						val.unique = parseInt(val.unique);
						return val;
					})
				});
			}).catch(err => {
				if (this.state.version !== version|| !err) return;
				if (err.errors && err.errors[0]) {
					err = err.errors[0];
				}
				if (err.status === 418 || Date.now() - reqStart > 20000) {
					// Over 20 seconds, let's assume this is a connection timeout
					return setTimeout(tryGet, 10000);
				}
				console.error(err);
				this.setState({ loading: false, error: err });
			});
		};
		return tryGet();
	}

	selectReport(report) {
		this.setState({ selectedReport: report });
		this.getReportData(report);
	}

	render(props, state) {
		let { data, keys, dataType, loading, error } = state;

		let content;
		if (loading) {
			content = <div class={s.loading}><div class={s.icon} /></div>;
		} else if (error) {
			content = <div class={s.error}>Error getting data</div>;
		} else if (data) {
			if (!data.length) {
				content = <div class={s.noData}>No results</div>;
			} else {
				content = [
					// <div class={s.actions}>
					// 	<div class={s.export}>GSheet</div>
					// </div>,
					<Table
						class={s.result + condClass(s[dataType])}
						data={data} keys={keys} keyTypes={this.keyTypes}
						defaultSorting={[{ key: 'unique', dir: -1 }, 'title']}
						columnClasses={this.columnClasses}
					/>
				];
			}
		}
		return (
			<div class={s.container}>
				<h2 class={s.legend}>Reports</h2>
				<div class={s.content}>
					<div class={s.settings}>
						<div class={s.date}>
							<label class={s.from}>
								<div class={s.description}>from</div>
								<div class={s.inputWrapper}>
									<input class={!state.start && s.empty} type="date" value={this.state.start} onChange={this.linkState('start')} />
									<div class={s.cover}>4ever</div>
								</div>
							</label>
							<label class={s.to}>
								<div class={s.description}>to</div>
								<div class={s.inputWrapper}>
									<input class={!state.end && s.empty} type="date" value={this.state.end} onChange={this.linkState('end')} />
									<div class={s.cover}>now</div>
								</div>
							</label>
						</div>
					</div>
					<div class={s.buttons}>
						{this.reports.map(r => (
							<button class={s.button} onClick={this.selectReport.bind(this, r)}>{r.name}</button>
						))}
					</div>
					{content}
				</div>
			</div>
		);
	}
}
