import { h, Component } from 'preact';
import Store from 'store/store';
import { HIDE_MODAL, SHOW_MODAL } from 'store/actions';
import { joinClasses } from 'utils/utils';
import { clamp } from 'utils/math';

import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';

import s from 'components/shared/editor/formEditor/attributes/cursorAttribute.sss';

const keywords = [
	'default',
	'none',
	'context-menu',
	'help',
	'pointer',
	'progress',
	'wait',
	'cell',
	'crosshair',
	'text',
	'vertical-text',
	'alias',
	'copy',
	'move',
	'no-drop',
	'not-allowed',
	'grab',
	'grabbing',
	'e-resize',
	'n-resize',
	'ne-resize',
	'nw-resize',
	's-resize',
	'se-resize',
	'sw-resize',
	'w-resize',
	'ew-resize',
	'ns-resize',
	'nesw-resize',
	'nwse-resize',
	'col-resize',
	'row-resize',
	'all-scroll',
	'zoom-in',
	'zoom-out',
];

const mediaBases = {
	stage: 'https://s3-eu-west-1.amazonaws.com/st-static-stage',
	prod: 'https://s3-eu-west-1.amazonaws.com/st-static-prod',
};
const getMediaRoots = (appData, type = 'stage') => {
	const [studio, title, region] = ['studio', 'title', 'region'].map(key => appData?.meta?.[key]?.slug);
	if (!studio || !title || !region) return [];
	const base = mediaBases[type] || '';
	const titleRoot = `${base}/${studio}/${title}`;
	return Object.entries({
		STATIC_ROOT: base,
		SHARED_IMAGE_ROOT: `${base}/images`,
		STUDIO_ROOT: `${base}/${studio}`,
		IMAGE_STUDIO_ROOT: `${base}/${studio}/images`,
		MEDIA_ROOT: titleRoot,
		IMAGE_ROOT: `${titleRoot}/images`,
		IMAGE_REGION_ROOT: `${titleRoot}/images/regions/${region}`,
		VIDEO_ROOT: `${titleRoot}/video`,
	});
	// return [];
};

function replaceMediaRoots(str, appData, type = 'stage') {
	return getMediaRoots(appData, type).reduce(
		(url, [key, val]) => url.replace(new RegExp(`%${key}%`, 'g'), val),
		str
	);
}

function parseCursorValue(value) {
	if (!value || typeof value !== 'string' || value === 'auto') return [];
	const parts = value.split(',').map(part => {
		part = part.trim();
		const partLower = part.toLowerCase();
		if (keywords.includes(partLower)) {
			return { mode: 'keyword', keyword: partLower };
		}
		// Only simple formats for now (no src(), no image-set())
		const parsed = part.match(/^url\(((')[^']+'|(")[^"]+"|[^)]+)\)(?:\s+(\d+)\s+(\d+))?$/i);
		if (!parsed) return null;
		const [, urlRaw, singleQ, doubleQ, x, y] = parsed;
		const url = singleQ || doubleQ ? urlRaw.slice(1, -1) : urlRaw;
		return { mode: 'custom', image: url, x: +x || 0, y: +y || 0 };
	}).filter(e => e);
	// Enforce final element is fallback ?
	return parts;
}

export default class CursorAttribute extends DisplayAttribute {

	constructor(props) {
		super(props);
		this.edit = this.edit.bind(this);
	}

	updateValue = (e) => {
		let value = e.target.value + '';
		this.setState({ value });
		this.onSave();
	};

	edit() {
		Store.emit(SHOW_MODAL, <EditCursorModal value={this.state.value} appData={this.props.activeValueFull} />, 'CURSOR EDITOR', 'cursorEditor', async value => {
			if (!value) {
				return;
			}
			this.setState({ value });
			this.onSave();
		}, {
			key: Date.now(),
			// Prevent light dismiss when clicking on backdrop
			// blockClose: true,
		});
	}

	getInput = (savedValue, description, jsonSchema) => {
		let value = this.state.value;
		const parsed = parseCursorValue(value);

		let display = 'Automatic';
		const displayStyle = {};
		if (parsed.length) {
			displayStyle.cursor = replaceMediaRoots(value, this.props.activeValueFull);
			// only display the first one
			const main = parsed[0];
			if (main.mode === 'keyword') {
				display = main.keyword;
			} else if (main.mode === 'custom') {
				display = [<img class={s.preview} src={replaceMediaRoots(main.image, this.props.activeValueFull)} alt="" />, <span>Custom</span>];
			}
		}

		return (
			<div class={s.wrapper}>
				<button class={s.edit} type="button" onClick={this.edit} key="edit">✏️</button>
				<div class={s.textarea} style={displayStyle} key="display">{display}</div>
				{/* {dialog} */}
			</div>
		);
	};

}

class EditCursorModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			mode: 'auto',
			keyword: 'default',
			x: 0,
			y: 0,
			image: '',
			imageWidth: 0,
			imageHeight: 0,
			mediaBucket: 'stage',
		};
		const data = parseCursorValue(props.value);
		if (!data.length) {
			this.state.mode = 'auto';
		} else if (data[0].mode === 'keyword') {
			this.state.mode = 'simple';
			this.state.keyword = data[0].keyword;
		} else if (data[0].mode === 'custom') {
			this.state.mode = 'custom';
			this.state.image = data[0].image;
			this.state.x = data[0].x;
			this.state.y = data[0].y;
			const fallback = data.at(-1);
			if (fallback.mode === 'keyword') {
				this.state.keyword = fallback.keyword;
			}
		}
		this.setMode = this.setMode.bind(this);
		this.setKeyword = this.setKeyword.bind(this);
		this.setImage = this.setImage.bind(this);
		this.setX = this.setX.bind(this);
		this.setY = this.setY.bind(this);
		this.autofillImage = this.autofillImage.bind(this);
		this.onPreviewLoad = this.onPreviewLoad.bind(this);
		this.onPreviewError = this.onPreviewError.bind(this);
		this.onPreviewMouseMove = this.onPreviewMouseMove.bind(this);
		this.onPreviewMouseDown = this.onPreviewMouseDown.bind(this);
		this.onPreviewMouseLeave = this.onPreviewMouseLeave.bind(this);
		this.cancel = this.cancel.bind(this);
		this.confirm = this.confirm.bind(this);
	}

	getCursorString() {
		if (this.state.mode === 'auto') {
			return 'auto';
		}
		if (this.state.mode === 'simple') {
			return this.state.keyword || 'auto';
		}
		if (this.state.mode === 'custom') {
			return `url('${this.state.image}') ${this.state.x || 0} ${this.state.y || 0}, ${this.state.keyword || 'auto'}`;
		}
	}

	setMode(e) {
		this.setState({ mode: e.target.value });
	}
	setKeyword(e) {
		this.setState({ keyword: e.target.value });
	}
	setImage(e) {
		this.setState({ image: e.target.value, imageWarning: '', imageError: '', imageWidth: 0, imageHeight: 0 });
	}
	setX(e) {
		this.setState({ x: clamp(Math.round(+e.target.value || 0), e.target.min, e.target.max) });
	}
	setY(e) {
		this.setState({ y: clamp(Math.round(+e.target.value || 0), e.target.min, e.target.max) });
	}

	autofillImage() {
		this.setState({ image: '%IMAGE_ROOT%/cursor.webp' });
	}

	onPreviewLoad(e) {
		const imageWidth = e.currentTarget.naturalWidth;
		const imageHeight = e.currentTarget.naturalHeight;
		let warning = '';
		if (imageWidth > 32 || imageHeight > 32) {
			warning = `Max recommended: 32x32\nCurrent image: ${imageWidth}x${imageHeight}`;
		}
		this.setState({ imageWidth, imageHeight, imageWarning: warning });
	}
	onPreviewError() {
		this.setState({ imageError: 'Image failed to load' });
	}

	getPreviewCoordinates(mouseEvent) {
		const w = this.state.imageWidth || 0;
		const h = this.state.imageHeight || 0;
		return {
			x: clamp(Math.round(mouseEvent.offsetX / mouseEvent.currentTarget.clientWidth * w), 0, w),
			y: clamp(Math.round(mouseEvent.offsetY / mouseEvent.currentTarget.clientHeight * h), 0, h),
		};
	}
	onPreviewMouseMove(e) {
		if (e.target !== e.currentTarget) return;
		const coords = this.getPreviewCoordinates(e);
		e.currentTarget.style.setProperty('--x', coords.x);
		e.currentTarget.style.setProperty('--y', coords.y);
	}
	onPreviewMouseDown(e) {
		if (e.target !== e.currentTarget) return;
		const coords = this.getPreviewCoordinates(e);
		this.setState(coords);
	}
	onPreviewMouseLeave(e) {
		e.currentTarget.style.setProperty('--x', this.state.x || 0);
		e.currentTarget.style.setProperty('--y', this.state.y || 0);
	}

	cancel() {
		Store.emit(HIDE_MODAL);
	}

	confirm() {
		if (this.state.mode === 'custom' && !this.state.image) {
			// this.setState({ submitError: 'Image is required' });
			const imageInput = this.base.querySelector('input[type="text"]');
			if (imageInput) {
				imageInput.setCustomValidity('Please select an image');
				imageInput.reportValidity();
			}
			return;
		}
		const value = this.getCursorString();
		Store.emit(HIDE_MODAL, value);
	}

	renderSettings() {
		const state = this.state;
		if (state.mode === 'auto') {
			return null;
		}
		const keywordSelector = (
			<select name="keyword" value={state.keyword} onChange={this.setKeyword} key="keyword">
				{keywords.map(keyword => <option value={keyword}>{keyword}</option>)}
			</select>
		);
		if (state.mode === 'simple') {
			return (
				<div class={s.settings} key="settings">
					<label class={s.label} key="keyword-label">
						<span>Cursor</span>
						{keywordSelector}
					</label>
				</div>
			);
		}
		if (state.mode === 'custom') {
			return (
				<div class={s.settings} key="settings">
					<div class={s.imagePreview} key="preview">
						<div class={s.options}>
							<div class={s.color}>
								<div class={s.colorInner}>
									<input type="radio" name="preview-bg" value="checker" />
									<input type="radio" name="preview-bg" value="white" />
									<input type="radio" name="preview-bg" value="black" />
									{/* <input type="radio" name="preview-bg" value="grey" /> */}
								</div>
							</div>
							{/* TODO: option to load image from stage or prod */}
						</div>
						<div
							class={s.previewWrapper}
							style={`--w: ${state.imageWidth || 0}; --h: ${state.imageHeight || 0}; --x: ${state.x || 0}; --y: ${state.y || 0};`}
							onMouseMove={this.onPreviewMouseMove}
							onMouseDown={this.onPreviewMouseDown}
							onMouseLeave={this.onPreviewMouseLeave}
						>
							{!!state.image && <img src={replaceMediaRoots(state.image, this.props.appData, state.mediaBucket)} alt="" onLoad={this.onPreviewLoad} onError={this.onPreviewError} />}
							<div class={joinClasses(s.warning, state.imageError && s.error)}>{state.imageError || state.imageWarning}</div>
						</div>
					</div>
					<div class={s.fields} key="fields">
						<label class={s.label} key="image-label">
							<span>Image</span>
							<input type="text" name="image" value={state.image} onChange={this.setImage} />
							<button class={joinClasses(s.autofill, state.image && s.hidden)} title="Auto-fill" onClick={this.autofillImage}>✨</button>
						</label>
						<label class={s.label} key="anchor-label">
							<span title="Position of the tip of the cursor">Anchor</span>
							<input type="number" name="x" step="1" min="0" max={state.imageWidth || 128} value={state.x} onInput={this.setX} />
							<input type="number" name="y" step="1" min="0" max={state.imageHeight || 128} value={state.y} onInput={this.setY} />
						</label>
						<label class={s.label} key="keyword-label">
							<span title="Cursor to show if the image cannot be loaded">Fallback</span>
							{keywordSelector}
						</label>
					</div>
				</div>
			);
		}
	}

	render() {
		const testZoneCursor = replaceMediaRoots(this.getCursorString(), this.props.appData);
		return (
			<div class={s.modal}>
				<div class={s.content}>
					<label class={joinClasses(s.label, s.mode)} key="keyword-label">
						<span>Mode</span>
						<select value={this.state.mode} onChange={this.setMode} key="mode">
							<option value="auto">Automatic</option>
							<option value="simple">Simple</option>
							<option value="custom">Custom</option>
						</select>
					</label>
					{this.renderSettings()}
					<fieldset class={s.testZone} style={`--cursor: ${testZoneCursor === 'auto' ? 'revert' : testZoneCursor};`} key="test-zone">
						<legend>Test Zone</legend>
						<div>
							<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quidem pariatur minus consectetur odit dicta deleniti aperiam ea praesentium! Eaque consequatur pariatur necessitatibus dicta non in aliquam et odit corrupti similique!</p>
							<button type="button">Button</button>
							<p>Porro optio natus <a href="https://example.com" target="_blank">link</a> dolorem reprehenderit iusto ullam ipsa, expedita molestiae deleniti sit, veniam tempora officia ratione aliquid impedit dignissimos repellat blanditiis ab officiis. Sequi ab odio exercitationem minima, blanditiis nulla?</p>
							<img src="https://placehold.co/300x150" alt="" />
							<p>Beatae sed minima, corrupti nisi quis omnis veniam dolore voluptatem accusantium iusto ipsum recusandae dolorum necessitatibus reiciendis quibusdam aspernatur soluta culpa velit nostrum nihil libero expedita facilis. Fugiat, velit quam.</p>
						</div>
					</fieldset>
				</div>
				<div class={s.buttons}>
					<button type="button" class={s.cancel} onClick={this.cancel}>Cancel</button>
					<button type="button" class={s.confirm} onClick={this.confirm}>Ok</button>
				</div>
			</div>
		);
	}

}