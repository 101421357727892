import { h, Component } from 'preact';
const defaultsDeep = require('@nodeutils/defaults-deep');
import Store from 'store/store';
import * as actions from 'store/actions';
import { accessNested } from 'utils/utils';
import cloneDeep from '@nodeutils/defaults-deep';
import pasteData from 'utils/pasteData';
import prodConf from '<%SRC_DIR%>/../../configs/production.js';
import expandAppData from 'utils/expandAppData';
import checkCondition from 'utils/checkCondition';
import StringAttribute from 'components/shared/editor/formEditor/attributes/stringAttribute';
// import ImageAttribute from 'components/shared/editor/formEditor/attributes/imageAttribute';
import ObjectAttribute from 'components/shared/editor/formEditor/attributes/objectAttribute';
import DateAttribute from 'components/shared/editor/formEditor/attributes/dateAttribute';
import ColorAttribute from 'components/shared/editor/formEditor/attributes/colorAttribute';
import CursorAttribute from 'components/shared/editor/formEditor/attributes/cursorAttribute';
import BackgroundAttribute from 'components/shared/editor/formEditor/attributes/backgroundAttribute';
import CodeAttribute from 'components/shared/editor/formEditor/attributes/codeAttribute';
import DateTimeAttribute from 'components/shared/editor/formEditor/attributes/dateTimeAttribute';
import BooleanAttribute from 'components/shared/editor/formEditor/attributes/booleanAttribute';
import ChecklistAttribute from 'components/shared/editor/formEditor/attributes/checklistAttribute';
// import ArrayAttribute from 'components/shared/editor/formEditor/attributes/arrayAttribute';
import NullAttribute from 'components/shared/editor/formEditor/attributes/nullAttribute';
import HiddenAttribute from 'components/shared/editor/formEditor/attributes/hiddenAttribute';
import YoutubePlaylistAttribute from 'components/shared/editor/formEditor/attributes/youtubePlaylistAttribute/youtubePlaylistAttribute';
import PageAttribute from 'components/shared/editor/formEditor/attributes/pageAttribute/pageAttribute';

import s from 'components/shared/editor/formEditor/attributes/attribute.sss';

const typesMap = {
	date: DateAttribute,
	datetime: DateTimeAttribute,
	color: ColorAttribute,
	cursor: CursorAttribute,
	simpleBackgroundStyle: BackgroundAttribute,
	boolean: BooleanAttribute,
	object: ObjectAttribute,
	array: ObjectAttribute,
	youtubePlaylistVideo: YoutubePlaylistAttribute,
	youtubePlaylistPlaylist: YoutubePlaylistAttribute,
	vimeoVideo: YoutubePlaylistAttribute,
	disneyKalturaVideo: YoutubePlaylistAttribute,
	boltYoutube: ObjectAttribute,
	boltYoutubePlaylist: ObjectAttribute,
	boltVimeo: ObjectAttribute,
	boltNativeVideo: ObjectAttribute,
	boltDisneyKalturaVideo: ObjectAttribute,
	boltGalleryImage: ObjectAttribute,
	boltNavItem: ObjectAttribute,
	boltShareItem: ObjectAttribute,
	page: PageAttribute,
	string: StringAttribute,
	// image: ImageAttribute,
	json: ObjectAttribute,
	hidden: HiddenAttribute,
	providersRegion: ObjectAttribute,
	code: CodeAttribute,
	checklist: ChecklistAttribute
};

export default class Attribute extends Component {

	constructor() {
		super();
		this.contextMenu = this.contextMenu.bind(this);
		this.copyLink = this.copyLink.bind(this);
		this.updateUrl = this.updateUrl.bind(this);
		this.copyHumanPath = this.copyHumanPath.bind(this);
		this.copyDevPath = this.copyDevPath.bind(this);
		this.copyThis = this.copyThis.bind(this);
		this.copyContent = this.copyContent.bind(this);
		this.pasteSibling = this.pasteSibling.bind(this);
		this.pasteInto = this.pasteInto.bind(this);
		this.cleanContent = this.cleanContent.bind(this);
		// this.cleanContentCurrent = this.cleanContent.bind(this, false);
		// this.cleanContentParent = this.cleanContent.bind(this, true);
		this.resetDefault = this.resetDefault.bind(this);
		this.disable = this.disable.bind(this);
		this.enable = this.enable.bind(this);
		this.rename = this.rename.bind(this);
	}

	componentDidMount() {
		// We could use onContextMenu but we would need to ensure every child class puts it on its base
		if (this.base) {
			this.base.addEventListener('contextmenu', this.contextMenu);
		}
	}

	// Need to make sure to reset the listener on update since the dom ref can change
	componentWillUpdate() {
		if (this.base) {
			this.base.removeEventListener('contextmenu', this.contextMenu);
		}
	}

	componentDidUpdate() {
		if (this.base) {
			this.base.addEventListener('contextmenu', this.contextMenu);
		}
	}

	componentWillUnmount() {
		if (this.base) {
			this.base.removeEventListener('contextmenu', this.contextMenu);
		}
	}

	contextMenu(event) {
		if (['input', 'textarea'].indexOf(event.target.nodeName.toLowerCase()) !== -1) return;

		const props = this.props;
		let path = props.attrKeyPath;
		if (path.endsWith('.')) path = path.slice(0, -1);
		path = path.split('.');

		// const name = path.pop().replace(/\[\[\[dot\]\]\]/g, '.');

		const activeType = props.branch ? 'branches' : props.activeType;
		const activeLevel = props.jsonPaths[props.attrKeyPath]?.includes(activeType);

		let extra = event.ctrlKey || event.metaKey;
		let copyPath = { name: 'Copy human path', action: this.copyHumanPath, autoClose: true };
		let copyDevPath = { name: 'Copy dev path', action: this.copyDevPath, autoClose: true };
		let copyLink = { name: 'Copy shareable link', action: this.copyLink, autoClose: true };
		let updateUrl = { name: 'Update current page URL', action: this.updateUrl, autoClose: true };

		let groups = [];

		if (this.props.base) {
			groups = [
				[
					{ name: 'Copy all content', action: this.copyContent, autoClose: true },
					{ name: 'Paste into', action: this.pasteInto, autoClose: true },
				],
				extra && [copyPath, copyDevPath, copyLink, updateUrl],
				extra && [{ name: 'Clean content', action: this.cleanContent, autoClose: true }]
			];
		} else {
			let disabled = props.value === null;
			groups = [
				[
					{ name: 'Copy', action: this.copyThis, autoClose: true },
					!disabled && { name: 'Copy content', action: this.copyContent, autoClose: true },
					{ name: 'Paste as sibling', action: this.pasteSibling, autoClose: true },
				],
				extra && [copyPath, copyDevPath, copyLink, updateUrl],
				[
					activeLevel && !disabled && { name: 'Rename', action: this.rename, autoClose: true },
					{ name: disabled ? 'Enable' : 'Disable', action: disabled ? this.enable : this.disable, autoClose: true },
					activeLevel && { name: 'Remove / Reset', action: this.resetDefault, autoClose: true },
				],
				extra && [{ name: 'Clean parent content', action: this.cleanContent, autoClose: true }]
			];
		}

		let items = groups.filter(e => e).flatMap((g, i) => [i && { separator: true }, ...g].filter(e => e));
		if (!items.length) {
			return;
		}

		event.preventDefault();
		event.stopPropagation();
		Store.emit(actions.SHOW_CONTEXT_MENU, { x: event.clientX, y: event.clientY }, items.filter(e => e));
	}

	createHiddenInput() {
		const input = document.createElement('textarea');
		input.className = s.hiddenInput;
		document.body.appendChild(input);
		return input;
	}

	copySomething(copyText) {
		if (typeof copyText !== 'string') copyText = JSON.stringify(copyText, null, '\t');
		const input = this.createHiddenInput();
		input.value = copyText;
		input.select();

		try {
			document.execCommand('copy');
		} catch (err) { /* Copy fails silently */ }

		document.body.removeChild(input);
	}

	copyLink() {
		try {
			let path = (this.props.attrKeyPath || '').replace(/\.$/, '');
			let url = new URL(location.href);
			url.searchParams.set('path', path);
			this.copySomething(url.toString());
		} catch (e) {}
	}
	updateUrl() {
		try {
			let path = (this.props.attrKeyPath || '').replace(/\.$/, '');
			let url = new URL(location.href);
			url.searchParams.set('path', path);
			history.replaceState({}, '', url.toString());
		} catch (e) { console.log('Error updating url', e); }
	}

	copyThis() {
		const props = this.props;
		let path = props.attrKeyPath;
		if (path.endsWith('.')) path = path.slice(0, -1);
		path = path.split('.');
		const elem = path.pop();
		// const value = props.parent[elem];
		const value = props.value;
		const copyObj = {};
		copyObj[elem] = value;
		this.copySomething(copyObj);
	}

	copyContent() {
		this.copySomething(this.props.value);
	}

	copyHumanPath() {
		try {
			let schema = this.props.baseJSONSchema || this.props.jsonSchema || {};
			let path = (this.props.attrKeyPath || '').replace(/\.$/, '').split('.');
			let humanPath = path.map(attr => {
				let newSchema = accessNested(schema, ['properties', attr]);
				let allOf = accessNested(schema, ['properties', 'allOf']);
				if (!newSchema && (allOf && allOf.length)) {
					newSchema = allOf.reduce((o, e) => cloneDeep(o, e), {});
				}
				schema = newSchema;
				return schema && schema.title || attr;
			}).join(' > ');
			this.copySomething(humanPath);
		} catch (e) {}
	}
	copyDevPath() {
		try {
			let parts = (this.props.attrKeyPath || '').replace(/\.$/, '').split('.');
			let path = parts.map(e => {
				let validIdentifier = /^[a-z$_][a-z0-9$_]*$/i.test(e);
				return validIdentifier ? '.' + e : '[\'' + e.replace(/'/g, '\\\'') + '\']';
			}).join('');
			this.copySomething(path);
		} catch (e) {}
	}

	json(d) {
		try {
			return JSON.parse(d);
		} catch (e) {
			// Error
			console.log('Invalid JSON', d, e);
		}
	}

	recursiveMerge(path, src, newData, deep) {
		let list = [];

		for (let k in newData) {
			const srcV = src[k];
			let newV = newData[k];

			// Have to check if defined first (because of a babel bug ?)
			const srcIsObj = srcV && typeof srcV === 'object';
			const newVIsObj = newV && typeof newV === 'object';
			if (deep && srcIsObj && newVIsObj) {
				const subModifs = this.recursiveMerge(path + k + '.', srcV, newV, deep);
				if (!subModifs) return false;
				list = list.concat(subModifs);
			} else {
				if (deep && srcV === newV) continue;
				if (src[k] !== undefined && !this.overrideAll) {
					const changes = srcIsObj || newVIsObj ? '' : '\n"' + srcV + '" -> "' + newV + '"';

					/* eslint-disable no-alert */
					const newName = prompt(`
${path + k} already exists, override ?${changes}

Don't change the name to override the current value
Enter a new name to add the new data under a different name
Cancel to leave the current value as it is
Type "yes!!!" without quotes to override everything, "no!!!" to cancel the merging`,
					k);
					/* eslint-enable no-alert */

					if (!newName) continue;
					if (newName.toLowerCase() === 'yes!!!') {
						this.overrideAll = true;
					} else if (newName.toLowerCase() === 'no!!!') {
						return false;
					} else {
						k = newName;
					}
				}

				if (srcIsObj && newVIsObj) {
					newV = defaultsDeep(newV, src[k]);
				}

				const obj = { path: path + k, value: newV };
				if (newV === null) obj.disable = true;

				list.push(obj);
			}
		}

		return list;
	}

	async mergeWith(path, src, newData, deep, overrideAll) {
		if (!newData || typeof newData !== 'object' || typeof src !== 'object') return;

		path = path || '';
		if (path && !path.endsWith('.')) path += '.';

		this.overrideAll = deep || overrideAll;

		if (deep) {
			const expanded = await expandAppData(JSON.parse(JSON.stringify(this.props.activeValueFull)), 'prod', prodConf);
			src = path ? accessNested(expanded, path.slice(0, -1), {}) : expanded;
		}

		const list = this.recursiveMerge(path, src, newData, deep);
		if (list && list.length) this.props.updateJSON(list);
	}

	pasteSibling(evt) {
		const deep = evt.metaKey || evt.ctrlKey;

		pasteData().then(this.json).then(d => {
			if (!d) return;
			const props = this.props;
			let path = props.attrKeyPath;
			if (path.endsWith('.')) path = path.slice(0, -1);
			const parentPath = path.split('.').slice(0, -1).join('.');

			this.mergeWith(parentPath, props.parent, d, deep);
		});
	}

	pasteInto(evt) {
		const deep = evt.metaKey || evt.ctrlKey;
		pasteData().then(this.json).then(d => {
			if (!d) return;
			this.mergeWith(this.props.attrKeyPath, this.props.value, d, deep);
		});
	}

	cleanContent() {
		this.props.cleanJSON(this.props.activePath);
	}

	resetDefault() {
		this.props.updateJSON(this.props.attrKeyPath, null);
	}

	disable() {
		this.props.updateJSON(this.props.attrKeyPath, null, true);
	}

	enable() {
		this.props.updateJSON(this.props.attrKeyPath, null, false, true);
	}

	rename() {
		Store.emit(actions.HIDE_CONTEXT_MENU);
		const path = this.props.attrKeyPath.split('.').filter(e => e);
		const name = path.pop();
		// TODO: not use prompt and put a nice-looking input in a modal or something
		const newName = prompt('New name', name); // eslint-disable-line no-alert
		if (!newName || newName === name) return;
		this.props.renameElement(path.join('.'), name, newName);
	}

	render(props) {
		let {
			value,
			// activeValue,
			activeValueFull,
			// parent,
			base = false,
			// setErrors,
			attrKeyPath,
			// updateJSON,
			// jsonPaths,
			baseJSONSchema,
			jsonSchema,
			// setActivePath,
			// activeType,
			// activePath,
			// parentType,
			dynamicSchemaRef,
			// attributeName
		} = props;

		let type = jsonSchema?.type || typeof value;

		if (jsonSchema?.$ref) {
			const { $ref, ...rest } = jsonSchema;
			let definitionName = $ref.split('/').pop();
			let refData = baseJSONSchema.definitions[definitionName];
			type = (base && refData?.baseType) || refData?.type;
			jsonSchema = { ...refData, ...rest };
		}

		if (dynamicSchemaRef?.[attrKeyPath]) {
			const definition = dynamicSchemaRef[attrKeyPath];
			type = baseJSONSchema.definitions[definition]?.type;
		}

		// Prevent bug if expected object but value is string
		// Urgh, not sure how to do that correctly, let's hardcode incompatible type for now
		let objectTypes = ['object', 'array', 'youtubePlaylistVideo', 'youtubePlaylistPlaylist', 'vimeoVideo', 'page', 'disneyKalturaVideo', 'imageContent', 'boltYoutube', 'boltYoutubePlaylist', 'boltVimeo', 'boltNativeVideo', 'providersRegion'];
		if (objectTypes && objectTypes.contain && objectTypes?.contains(type) && typeof value !== 'object') {
			type = typeof value;
		}

		let ClassName = NullAttribute;
		if (value !== null || type === 'hidden') {
			ClassName = typesMap[type] || StringAttribute;
		}

		// console.log(attrKeyPath, jsonSchema);
		let hidden = jsonSchema?.hidden;
		if (hidden && typeof hidden === 'object') {
			let currentAttribute = attrKeyPath.split('.').at(-2);
			hidden = checkCondition(hidden, activeValueFull, { localPath: props.activePath, currentAttribute });
		}
		if (hidden) {
			ClassName = HiddenAttribute;
		}

		return <ClassName {...this.props} key={this.__key} />;
	}

}
