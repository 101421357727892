import { h } from 'preact';
import Store from 'store/store';
// import pure from 'utils/pure';
import { accessNested } from 'utils/utils';

import DisplayAttribute from 'components/shared/editor/formEditor/attributes/displayAttribute';
import ColorInput from 'components/shared/colorInput/colorInput';
import Color from 'components/shared/colorInput/color';

import s from 'components/shared/editor/formEditor/attributes/colorAttribute.sss';

let onesheetCache = {};

export default class ColorAttribute extends DisplayAttribute {

	// Cannot do that because needs to listen for jsonPaths change. Should we check if our key specifically changed though ?
	// shouldComponentUpdate(props, state) {
	// 	// return state.value !== this.state.value;
	// 	console.log(Object.keys(props).filter(k => props[k] !== this.props[k]));
	// 	return true;
	// }

	updateValue = (e) => {
		let value = e.target.value + '';
		this.setState({ value });
		this.onSave();
	};

	getInput = (savedValue, description, jsonSchema) => {
		let input;

		let value = this.state.value;
		let resolvedValue = this.state.value;
		let resolvedPath;
		let firstResolve;
		let iter = 0;
		let overrideOpacity = null;
		while (resolvedValue && resolvedValue[0] === '$' && ++iter < 10) {
			let opacityMatch = resolvedValue.match(/^(.*\D)(\d{1,3})$/);
			if (opacityMatch) {
				resolvedValue = opacityMatch[1];
				overrideOpacity = opacityMatch[2] / 100;
			}
			let parentPath = this.props.activePath.includes('variables.customHeaderColorsPerSection') ? 'styling.variables.' : this.props.activePath;
			resolvedPath = parentPath + resolvedValue.slice(1);
			resolvedValue = accessNested(this.props.activeValueFull, resolvedPath);
			if (!firstResolve) {
				firstResolve = resolvedPath;
			}
		}
		if (iter >= 10) {
			resolvedValue = null;
		}
		let color = new Color();
		let colorOk = color.fromString(resolvedValue);
		if (overrideOpacity !== null) {
			color.a = overrideOpacity;
		}
		let colorStr = color.toString();

		// Not efficient (does the aggregation for every color at every render)
		let allColors = {};
		Object.keys(this.props.parent).forEach(key => {
			let val = this.props.parent[key];
			if (!val || typeof val !== 'string') {
				return;
			}
			let color = new Color();
			let ok = color.fromString(val);
			if (ok) {
				let str = color.toString();
				allColors[str] = (allColors[str] || 0) + 1;
			}
		});
		let colorSuggestions = Object.keys(allColors).sort((a, b) => allColors[b] - allColors[a]);
		let extractFromImages = {};

		if (this.props.activeType === 'apps' || this.props.activeType === 'titles') {
			let meta = this.props.activeValueFull?.meta;
			let studio = meta?.studio?.slug;
			let title = meta?.title?.slug;
			let region = meta?.region?.slug;
			let id = [studio, title, region].filter(e => e).join(' ');
			if (!onesheetCache[id]) {
				if (this.props.activeType === 'titles') {
					let store = Store.get();
					// Uglyyyyy, find another way.
					let studioId = +store.query?.studio;
					let titleId = +store.query?.title;
					let firstApp = Object.values(store.list.apps).find(app => (
						app.rel.title === titleId && app.rel.studio === studioId
					));
					let regionId = firstApp?.rel?.region || 1;
					region = store.list.regions?.[regionId]?.slug;
				}

				// Stage version
				let imageRoot = 'https://s3-eu-west-1.amazonaws.com/st-static-stage/';
				// Prod version
				// let imageRoot = 'https://dx35vtwkllhj9.cloudfront.net/';
				// ^ we most likely want the stage one as the styling is done at the beginning, when the movie is not live and assets are not on prod

				onesheetCache[id] = imageRoot + studio + '/' + title + '/images/regions/' + region + '/onesheet.jpg';
			}
			extractFromImages['Onesheet'] = onesheetCache[id];
		}

		input = (
			<div class={s.colorWrap}>
				<div class={s.colorBox} key="colorBox">
					{/* <input type="color" value={sanitized || '#000000'} onChange={this.updateValue} /> */}
					<ColorInput
						class={s.colorInput}
						value={colorStr || '#000000'}
						invalid={!colorOk}
						onChange={this.updateValue}
						suggestions={colorSuggestions}
						extractFromImages={extractFromImages}
					/>
				</div>
				<input
					type="text"
					class={s.textarea}
					value={value}
					onChange={this.updateValue}
					placeholder={description}
					key="text-input"
				/>
				{!!firstResolve && (
					<button
						class={s.view}
						key="view"
						title="see source color definition"
						onClick={() => this.props.setActivePath(firstResolve)}
					/>
				)}
			</div>
		);

		if (jsonSchema && jsonSchema.options && jsonSchema.options.possibilities) {
			input = (
				<select class={s.select} value={value} onChange={this.linkState('value')} onBlur={this.onSave}>
					{jsonSchema.options.possibilities.map((possibility) => {
						const extraProps = {};
						if (possibility === this.state.value) {
							extraProps.selected = 'selected';
						}
						return <option value={possibility} {...extraProps}>{possibility}</option>;
					})}
				</select>
			);
		}

		return input;
	};

}
